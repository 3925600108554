import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../layout/layout';
import { Stream } from '@cloudflare/stream-react';
import ReactMarkdown from 'react-markdown';
import { useCMSState } from '@internal/useCMSHook';
import { GlobalStrings, GlobalVariables } from '../../../common/fragments/MRKConfig';
import { LocaleLink } from '../../common/links/LocaleLink';
import { buildURL, HrefLangs } from '../../layout/refreshed-header/HrefLangs';
import { GatsbyTemplateProps } from '../../../common/interfaces/gatsby-template-props.interface';
import { Quotation } from '../../common/Quotation';
import { caseStudiesBasePath } from './config';
import { interpolateGlobalVariables } from '../../../common/util/interpolateGlobalVariables';
import { useLocale } from '../../../common/hooks/useLocale';
import { getMetaTagsJSX } from '../../page/page.template';
import { ContentfulAsset } from '../../../contentful/content-types/blades/common/interfaces/contentful-asset.interface';
import { MetaTags } from '../../../contentful/content-types/elements/meta-tags.interface';
import { Div } from '../../common/Elements';
import { Lazy } from '../../common/Lazy';
import { joinPaths } from '../../../common/util/path';
import { useConfiguration } from '../../../common/hooks/useConfiguration';
import { MainFooter } from '../../layout/refreshed-footer/footer.interfaces';
import { ContentfulNavNavigationGroup } from '../../../contentful/content-types/nav-navigation-group';

export const pageQuery = graphql`
  query caseStudyIndividual($contentfulId: String!, $locale: String!) {
    globalStrings: mrkConfigStaticStrings(
      contentfulId: { eq: "1uP0ac1EPF1qsviUi4I9Vz" }
      locale: { eq: $locale }
    ) {
      ...GlobalStringsFragment
    }

    globalVariables: mrkConfigGlobalVariables(contentfulId: { eq: "3ZcYKUNPY2VwmalOzE5inN" }) {
      ...GlobalVariablesFragment
    }
    headerData: navNavigationGroup(
      contentfulId: { eq: "3hzSjT58plRWzuQrC4j4ny" }
      locale: { eq: $locale }
    ) {
      ...navNavigationGroupFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }

    caseStudy(contentfulId: { eq: $contentfulId }, locale: { eq: $locale }) {
      name
      contentfulId
      nameUrlSlug
      metaTags {
        metaTitle
        metaDescription
        twitterCustomImage {
          file {
            publicURL
          }
        }
        metaImage {
          file {
            publicURL
          }
        }
        facebookCustomImage {
          file {
            publicURL
          }
        }
      }
      orderDate
      homepageUrl
      hideFromCaseStudiesPage
      shortDescription
      longDescription
      cloudflareStreamVideoId
      keyResults
      relatedCaseStudies {
        name
        nameUrlSlug
      }
      inPartnershipWith {
        title
        file {
          publicURL
        }
      }
      relatedCloudflareProducts {
        title
        url
      }
      employeeHeadshot {
        file {
          publicURL
        }
      }
      employeeQuoteName
      employeeQuoteText
      employeeQuoteJobTitle
      employeeQuoteName2
      employeeQuoteText2
      employeeQuoteJobTitle2
      downloadPdfButtonLink {
        file {
          publicURL
        }
      }
      ref_streamVideoThumbnail {
        file {
          publicURL
        }
      }
      logo {
        file {
          publicURL
        }
      }
      customCanonicalTag
      availableLocales
    }
    site {
      siteMetadata {
        baseURL
      }
    }
  }
`;

interface RelatedCaseStudies {
  kind: 'caseStudy';
  name: string;
  nameUrlSlug: string;
}
interface RelatedCloudflareProducts {
  kind: 'page';
  title: string;
  url: string;
}

interface CaseStudyProps {
  id: string;
  contentfulId: string;
  name?: string;
  nameUrlSlug?: string;
  metaTags?: MetaTags;
  orderDate?: string;
  homepageUrl?: string;
  hideFromCaseStudiesPage: boolean;
  shortDescription?: string;
  longDescription?: string;
  logo?: ContentfulAsset;
  cloudflareStreamVideoId?: string;
  ref_streamVideoThumbnail?: ContentfulAsset;
  downloadPdfButtonLink?: ContentfulAsset;
  keyResults?: string;
  employeeHeadshot?: ContentfulAsset;
  employeeQuoteName?: string;
  employeeQuoteText?: string;
  employeeQuoteJobTitle?: string;
  employeeQuoteName2?: string;
  employeeQuoteText2?: string;
  employeeQuoteJobTitle2?: string;
  relatedCaseStudies?: RelatedCaseStudies[];
  inPartnershipWith?: ContentfulAsset[];
  relatedCloudflareProducts?: RelatedCloudflareProducts[];
  customCanonicalTag?: string;
  availableLocales?: string[];
}

interface CaseStudyData {
  data: {
    caseStudy: CaseStudyProps;
    globalStrings: GlobalStrings;
    headerData: ContentfulNavNavigationGroup;
    globalVariables: GlobalVariables;
    footerData: MainFooter;
    site: any;
  };
}

function getStreamVideoContent(caseStudy: CaseStudyProps, baseURL: string) {
  if (!caseStudy.cloudflareStreamVideoId) {
    return null;
  }

  const thumbnail =
    caseStudy.ref_streamVideoThumbnail && caseStudy.ref_streamVideoThumbnail.file
      ? caseStudy.ref_streamVideoThumbnail.file.publicURL
      : '';

  const posterURL = joinPaths(baseURL, thumbnail);

  return (
    <Div>
      <Lazy>
        <Stream
          src={caseStudy.cloudflareStreamVideoId}
          preload="auto"
          controls
          poster={posterURL || thumbnail || undefined}
        />
      </Lazy>
    </Div>
  );
}

// Sidebar links functional component
const RelatedLinks: React.FC<{ header: string }> = ({ children, header }) => {
  return (
    <div className="docmenu">
      <div className="docmenu__separator grid--hide-desktop"></div>
      <div className="docmenu__heading js-docmenu-toggle pb2 bb b--black-10">{header}</div>
      <div className="docmenu__separator"></div>
      <div className="docmenu__subheading"></div>
      <ul className="docmenu__group pl0 list">{children}</ul>
    </div>
  );
};

const CaseStudyTemplate: FC<CaseStudyData & GatsbyTemplateProps<any>> = ({ data, pageContext }) => {
  const config = useConfiguration();
  let { caseStudy, globalStrings, globalVariables, headerData, footerData } = data;
  if (config.targetEnv === 'staging') {
    caseStudy = useCMSState<CaseStudyProps>(caseStudy);
    globalStrings = useCMSState<GlobalStrings>(globalStrings);
    headerData = useCMSState<ContentfulNavNavigationGroup>(headerData);
    footerData = useCMSState<MainFooter>(footerData);
    globalVariables = useCMSState<GlobalVariables>(globalVariables);
  }

  const { json_values } = globalStrings;
  const locale = useLocale();
  const pageTitle = `${caseStudy.name} | Cloudflare`;
  const url = buildURL(pageContext.locale, pageContext.pathname, pageContext.baseURL);
  const twitterImageUrl = `${pageContext.baseURL}/img/cf-twitter-card.png`;
  const baseURL = data.site?.siteMetadata?.baseURL || 'https://www.cloudflare.com';

  const interpolatedDescription = interpolateGlobalVariables(
    caseStudy.longDescription || '',
    locale,
    globalVariables.json_values
  );

  const fallbackMetaTags = [
    <meta name="title" content={caseStudy.name} />,
    <meta name="description" content={caseStudy.shortDescription} />,
    <meta name="twitter:card" content="summary" />,
    <meta name="twitter:title" content={pageTitle} />,
    <meta name="twitter:description" content={caseStudy.shortDescription} />,
    <meta name="twitter:image" content={twitterImageUrl} />,
    <meta property="og:title" content={pageTitle} />,
    <meta property="og:type" content="article" />,
    <meta property="og:url" content={url} />,
    <meta property="og:image" content={twitterImageUrl} />,
    <meta property="og:description" content={caseStudy.shortDescription} />,
  ];

  return (
    <Layout
      pageContext={pageContext}
      footerData={footerData}
      headerData={headerData}
      hrefLangs={HrefLangs(
        pageContext.locale,
        pageContext.pathname,
        pageContext.baseURL,
        (caseStudy.availableLocales || ['en-US']) as any
      )}
    >
      <Helmet>
        <title>{pageTitle}</title>
        {caseStudy.metaTags ? getMetaTagsJSX(baseURL, caseStudy.metaTags) : fallbackMetaTags}

        {caseStudy.customCanonicalTag && (
          <link rel="canonical" href={caseStudy.customCanonicalTag} />
        )}
      </Helmet>

      <section className="tile tile--borderless">
        <div className="tile__content tile__content--fixed">
          <div className="grid">
            <div className="grid__container">
              {/* Main content */}
              <div className="grid__item grid__item--two-thirds">
                <h1 className="tile__heading text-left--desktop">{caseStudy.name}</h1>

                {/* Render a Cloudflare Stream video player if Cloudflare Steam Video is supplied from Contentful */}
                {getStreamVideoContent(caseStudy, pageContext.baseURL)}

                {/* Renders the PDF link hosted on Cloudflare as a blue button in the top of the case study body */}
                {caseStudy.downloadPdfButtonLink && caseStudy.downloadPdfButtonLink.file ? (
                  <div className="text--row grid--show-desktop">
                    <a
                      href={caseStudy.downloadPdfButtonLink.file.publicURL}
                      target="_blank"
                      className="button--block button--blue button--stack-mobile"
                    >
                      {globalStrings.json_values.Download_PDF}
                    </a>
                  </div>
                ) : null}

                <ReactMarkdown
                  source={interpolatedDescription}
                  escapeHtml={false}
                  renderers={{
                    strong: ({ children }) => <strong className="b">{children}</strong>,
                  }}
                />
              </div>
              {/* Sidebar */}
              <div className="grid__item grid__item--third">
                {caseStudy.logo && caseStudy.logo.file && (
                  <a href={caseStudy.homepageUrl} target="_blank">
                    <img
                      src={caseStudy.logo.file.publicURL}
                      alt={caseStudy.name}
                      className="responsive-image responsive-image--no-margin responsive-image--padding-top-bottom-20px grid--show-desktop w-100"
                    />
                  </a>
                )}
                {caseStudy.inPartnershipWith && (
                  <RelatedLinks header={json_values.In_Partnership_With}>
                    {caseStudy.inPartnershipWith.map(link => (
                      <img
                        key={link.file?.publicURL}
                        src={link.file?.publicURL}
                        className="responsive-image responsive-image--no-margin responsive-image--padding-top-bottom-20px w-100"
                      />
                    ))}
                  </RelatedLinks>
                )}
                {caseStudy.relatedCaseStudies && (
                  <RelatedLinks header={json_values.Related_Case_Studies}>
                    {caseStudy.relatedCaseStudies.map(link => (
                      <li
                        className="docmenu__document"
                        key={`relatedCaseStudies-${link.nameUrlSlug}`}
                      >
                        <span className="icon icon-document"></span>
                        <LocaleLink to={`/${caseStudiesBasePath}/${link.nameUrlSlug}`}>
                          {link.name}
                        </LocaleLink>
                      </li>
                    ))}
                  </RelatedLinks>
                )}

                {caseStudy.relatedCloudflareProducts && (
                  <RelatedLinks header={json_values.Related_Products}>
                    {caseStudy.relatedCloudflareProducts.map(link => (
                      <li
                        className="docmenu__document"
                        key={`relatedCloudflareProducts-${link.url}`}
                      >
                        <span className="icon icon-document"></span>
                        <LocaleLink to={link.url}>{link.title}</LocaleLink>
                      </li>
                    ))}
                  </RelatedLinks>
                )}
                <div className="docmenu__separator docmenu__separator--nm"></div>

                {caseStudy.keyResults ? (
                  <div className="marketing-text-block">
                    <div className="text--row text--bold">{json_values.Key_Results}</div>
                    <div className="text--row text--light">
                      <ReactMarkdown source={caseStudy.keyResults} escapeHtml={false} />
                    </div>
                  </div>
                ) : null}

                {/* This is optional in Contentful and can also be used to show graphics */}
                {caseStudy.employeeHeadshot && caseStudy.employeeHeadshot.file ? (
                  <img
                    src={caseStudy.employeeHeadshot.file.publicURL}
                    alt={caseStudy.employeeQuoteName}
                    className="responsive-image grid--show-desktop"
                  />
                ) : null}

                {/* /case-studies/daily-caller/ is an example where there is no quote */}
                {caseStudy.employeeQuoteText ? (
                  <>
                    <QuotationMarkdown source={caseStudy.employeeQuoteText} />
                    <p className="text--small text-right--desktop">
                      <strong>{caseStudy.employeeQuoteName}</strong>
                      <br />
                      {caseStudy.employeeQuoteJobTitle}
                    </p>
                  </>
                ) : null}

                {caseStudy.employeeQuoteText2 ? (
                  <>
                    <QuotationMarkdown source={caseStudy.employeeQuoteText2} />
                    <p className="text--small text-right--desktop">
                      <strong>{caseStudy.employeeQuoteName2}</strong>
                      <br />
                      {caseStudy.employeeQuoteJobTitle2}
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const QuotationMarkdown: React.FC<{ source: string }> = ({ source }) => (
  <ReactMarkdown
    escapeHtml={false}
    source={source}
    renderers={{
      paragraph: ({ children }) => (
        <p className="text--bolder">
          <Quotation>{children}</Quotation>
        </p>
      ),
    }}
  />
);

export default CaseStudyTemplate;
